import { ref } from 'vue';
import { mapGetters, useStore } from 'vuex';
import { useForm } from 'vee-validate';
import FormInput from '@/components/FormInput';
import Loading from '@/components/Loading';
import Message from '@/components/Message';
export default {
  name: 'PasswordRecovery',
  components: {
    FormInput,
    Loading,
    Message
  },

  setup() {
    const store = useStore();
    const {
      handleSubmit,
      isSubmitting
    } = useForm();
    var recoveredPassword = ref(false);
    const onSubmit = handleSubmit(() => {
      store.dispatch('user/resetPassword').then(_ => {
        recoveredPassword.value = true;
      }).catch(_ => {
        recoveredPassword.value = false;
      });
    });
    return {
      onSubmit,
      isSubmitting,
      recoveredPassword
    };
  },

  computed: { ...mapGetters('user', ['loading', 'error', 'loginPassword', 'repeatedPassword']),

    notEmpty() {
      return this.loginPassword.value && this.loginPassword.value.length > 0 || this.repeatedPassword && this.repeatedPassword.length > 0;
    },

    oneUpperCase() {
      return new RegExp('[A-Z]').test(this.loginPassword.value);
    },

    oneLowerCase() {
      return new RegExp('[a-z]').test(this.loginPassword.value);
    },

    oneNumber() {
      return new RegExp('[0-9]').test(this.loginPassword.value);
    },

    noSpecialCharacters() {
      return !new RegExp('[^a-zA-Z0-9]').test(this.loginPassword.value) && !new RegExp('[^a-zA-Z0-9]').test(this.repeatedPassword);
    },

    eightCharLong() {
      return this.loginPassword.value.length >= 8;
    },

    matchingPassword() {
      return this.loginPassword.value === this.repeatedPassword;
    }

  },

  mounted() {
    this.loadRecoveryToken();
  },

  methods: {
    loadRecoveryToken() {
      this.$store.commit('user/recoveryToken', this.$route.query.recoveryToken);
      this.$store.commit('user/loginEmail', this.$route.query.email);
    },

    cancel() {
      this.$router.push({
        path: '/'
      });
    }

  }
};