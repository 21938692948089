export default {
  name: 'Message',
  props: {
    type: {
      type: String,
      required: false,
      default: 'info',
      validator: value => {
        return ['info', 'warning', 'error', 'download'].includes(value);
      }
    },
    message: {
      type: String,
      required: false,
      default: ''
    }
  }
};