import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1d8b0cd5"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message", 'message-' + $props.type])
  }, [_createElementVNode("span", {
    class: _normalizeClass('el-icon-' + $props.type)
  }, null, 2), $props.message ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.message), 1)) : _createCommentVNode("", true), !$props.message ? (_openBlock(), _createElementBlock("span", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])) : _createCommentVNode("", true)], 2);
}