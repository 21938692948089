import { mapGetters, useStore, mapActions } from 'vuex';
import { useForm } from 'vee-validate';
import FormInput from '@/components/FormInput';
import Loading from '@/components/Loading';
import Message from '@/components/Message';
export default {
  name: 'Login',
  components: {
    FormInput,
    Loading,
    Message
  },

  setup() {
    const store = useStore();
    const {
      handleSubmit,
      isSubmitting
    } = useForm();
    const onSubmit = handleSubmit(() => {
      store.dispatch('user/login');
    });
    return {
      onSubmit,
      isSubmitting
    };
  },

  data() {
    return {
      recoverPasswordToggle: false,
      recoveredPassword: false
    };
  },

  computed: { ...mapGetters('user', ['loginEmail', 'loginPassword', 'loggedIn', 'loading', 'error']),

    translateLoginErrors() {
      switch (this.error) {
        case 'InvalidLogin':
          return 'Fel email eller lösenord, vänligen försök igen';

        default:
          return 'Ett fel har inträffat, vänligen försök igen senare';
      }
    }

  },
  methods: { ...mapActions('user', ['recoverPassword']),

    recoverPasswordClick() {
      if (!this.recoverPasswordToggle) {
        this.recoverPasswordToggle = true;
      } else {
        this.recoverPassword();
        this.recoveredPassword = true;
      }
    },

    recoverPasswordCancel() {
      this.recoverPasswordToggle = false;
      this.recoveredPassword = false;
    }

  }
};