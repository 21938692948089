import FormInput from '@/components/FormInput';
import Loading from '@/components/Loading';
import Message from '@/components/Message';

const {
  serviceUser
} = require('@/lib/axios').default;

export default {
  name: 'PasswordRecovery',
  components: {
    FormInput,
    Loading,
    Message
  },

  data() {
    return {
      loading: true,
      error: false,
      verified: false,
      verificationToken: this.$route.query.verificationToken,
      userId: this.$route.query.userId
    };
  },

  mounted() {
    if (this.userId && this.verificationToken) {
      serviceUser.get(`/user/verify/${this.userId}/${this.verificationToken}`).then(_ref => {
        let {
          data
        } = _ref;
        this.verified = true;
        this.error = false;
      }).catch(e => {
        this.error = true;
      }).finally(() => {
        this.loading = false;
      });
    } else {
      this.error = true;
      this.loading = false;
    }
  },

  methods: {
    ok() {
      this.$router.push({
        path: '/'
      });
    }

  }
};